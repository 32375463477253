<template>
  <div class="visual-template-config">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="模板名称：" prop="templateNameDTOS">
        <div v-for="(item, key) in form.templateNameDTOS" :key="key" class="template-name">
          <el-select
            v-model="item.language"
            placeholder="请选择"
            size="small"
            style="width: 100px;"
            :disabled="templateLinked && item.fromDetail"
            @change="langOptionChange($event, key, item)"
          >
            <el-option
              v-for="itm in langOptions"
              :key="itm.value"
              :label="itm.label"
              :value="itm.value">
            </el-option>
          </el-select>
          <el-input maxlength="50" :disabled="templateLinked && item.fromDetail" v-model="item.deviceTemplateName" size="small" style="width: 280px;margin: 0 10px"></el-input>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addTemplateName" v-if="key + 1 === form.templateNameDTOS.length"></el-button>
          <el-button type="primary" icon="el-icon-minus" size="small" @click="deleteTemplateName(key, item)" v-if="form.templateNameDTOS.length !== 1 && (!item.fromDetail || !templateLinked)"></el-button>
        </div>
      </el-form-item>
      <el-form-item label="主副柜选择：" prop="deviceMainSub">
        <el-select v-model="form.deviceMainSub" placeholder="请选择" size="small" :disabled="templateLinked">
          <el-option
            v-for="item in deviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联内部型号：" prop="productCodeDTOS">
        <div v-for="(item, key) in form.productCodeDTOS" :key="key" class="template-name">
          <el-autocomplete
            v-model="item.productCode"
            :fetch-suggestions="getProductCodesOptions"
            placeholder="请选择内部型号"
            style="width: 200px;"
            size="small"
            @select="handleSelectProduct($event, key)"
            :disabled="!form.deviceMainSub || (templateLinked && item.fromDetail)"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.label }}</div>
            </template>
          </el-autocomplete>
          <!-- <el-select
            v-model="item.productCode"
            placeholder="请选择"
            size="small"
            style="width: 200px;"
            remote
            reserve-keyword
            :remote-method="getProductCodesOptions"
            :disabled="!form.deviceMainSub"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="itm in productCodeOptios"
              :key="itm.value"
              :label="itm.label"
              :value="itm.value">
            </el-option>
          </el-select> -->
          <el-input v-model="item.productName" size="small" style="width: 180px;margin: 0 10px" :disabled="!form.deviceMainSub || (templateLinked && item.fromDetail)" placeholder="请输入中文名称"></el-input>
          <el-button type="primary" icon="el-icon-plus" size="small" @click="addProductCode" v-if="key + 1 === form.productCodeDTOS.length"></el-button>
          <el-button type="primary" icon="el-icon-minus" size="small" @click="deleteProductCode(key, item)" v-if="form.productCodeDTOS.length !== 1 && (!item.fromDetail || !templateLinked)"></el-button>
        </div>
      </el-form-item>
      <el-form-item label="行数：" prop="deviceCellRows">
        <el-select v-model="form.deviceCellRows" placeholder="请选择" size="small" :disabled="templateLinked">
          <el-option
            v-for="item in rowOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="列数：" prop="deviceCellColumns">
        <el-select v-model="form.deviceCellColumns" placeholder="请选择" size="small" :disabled="templateLinked">
          <el-option
            v-for="item in columnsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="creates-footer">
        <el-button size="small" type="success" @click="submitForm">确定</el-button>
        <el-button size="small" @click="$router.go(-1)">取消</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getProductCodes, createTemplate, getTemplateDetail, getVendorList, checkTemplateLinked, editTemplate } from '@/api/modules/device'
export default {
  name: 'visual-config',
  data() {
    function setOptions(type) {
      const array = []
      if (type === 'row') {
        for (let i = 1; i < 26; i++) {
          array.push({
            value: i,
            label: `${i}行`
          })
        }
      } else {
        for (let i = 1; i < 6; i++) {
          array.push({
            value: i,
            label: `${i}列`
          })
        }
      }
      return array
    }
    return {
      form: {
        deviceCellRows: '',
        deviceCellColumns: '',
        deviceMainSub: '',
        productCodeDTOS: [{
          productCode: '',
          productName: '',
          fromDetail: false
        }],
        templateNameDTOS: [{
          deviceTemplateName: '',
          language: 'zh-CN',
          languageName: '中文',
          fromDetail: false
        }]
      },
      rules: {
        templateNameDTOS: {
          required: true,
          validator(rule, value, callback) {
            const errorItem = value.filter(item => !item.deviceTemplateName)
            if (errorItem.length) {
              return callback(new Error('请输入模板名称'))
            }
            return callback()
          }
        },
        deviceMainSub: {
          required: true, message: '请选择主副柜'
        },
        productCodeDTOS: {
          required: true,
          validator(rule, value, callback) {
            const errorItem = value.filter(item => !item.productCode || !item.productName)
            if (errorItem.length) {
              return callback(new Error('请输入关联内部型号'))
            }
            return callback()
          }
        },
        deviceCellRows: {
          required: true,
          message: '请选择行数'
        },
        deviceCellColumns: {
          required: true,
          message: '请选择列数'
        }
      },
      langOptions: [],
      deviceOptions: [{
        label: '主柜',
        value: 1
      }, {
        label: '副柜',
        value: 2
      }],
      productCodeOptios: [],
      columnsOptions: setOptions('column'),
      rowOptions: setOptions('row'),
      templateId: '',
      templateLinked: false
    }
  },
  created() {
    if (this.$route.query.id) {
      this.templateId = this.$route.query.id
      this.fetchDetail()
    }
    this.fetchLang()
  },
  methods: {
    fetchDetail() {
      this.fetchTemplateDetail()
      this.checkTempalteEdit()
    },
    handleSelectProduct(event, key) {
      console.log(event)
      this.form.productCodeDTOS[key].productCode = event.value
      this.form.productCodeDTOS[key].productName = event.name
    },
    fetchLang() {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: 'langConfig' }).then((res) => {
        const { records } = res.data || []
        this.langOptions = records.map(item => ({
          label: item.entryValue,
          value: item.itemValue
        }))
      })
    },
    fetchTemplateDetail() {
      getTemplateDetail(this.templateId).then(res => {
        res.data.productCodeDTOS.forEach(item => {
          item.fromDetail = true
        })
        res.data.templateNameDTOS.forEach(item => {
          item.fromDetail = true
        })
        const {
          deviceCellRows = '',
          deviceCellColumns = '',
          deviceMainSub = '',
          productCodeDTOS = [{
            productCode: '',
            productName: '',
            fromDetail: false
          }],
          templateNameDTOS = [{
            deviceTemplateName: '',
            language: 'zh-CN',
            languageName: '中文',
            fromDetail: false
          }]
        } = res.data
        this.form = {
          templateNameDTOS,
          deviceCellRows,
          deviceCellColumns,
          deviceMainSub,
          productCodeDTOS
        }
      })
    },
    checkTempalteEdit() {
      checkTemplateLinked(this.templateId).then(res => {
        this.templateLinked = res.data.linked
      })
    },
    addTemplateName() {
      const currentLangList = this.form.templateNameDTOS.map(item => item.language)
      const newLangItem = this.langOptions.filter(item => !currentLangList.includes(item.value))
      if (newLangItem.length) {
        this.form.templateNameDTOS.push(JSON.parse(JSON.stringify({
          deviceTemplateName: '',
          language: newLangItem[0].value,
          languageName: newLangItem[0].label,
          fromDetail: false
        })))
      } else {
        this.$message.warning('已添加所有语种')
      }
      console.log(newLangItem)
    },
    deleteTemplateName(index) {
      this.form.templateNameDTOS.splice(index, 1)
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.templateId) {
            editTemplate({
              ...this.form,
              deviceTemplateCode: this.templateId
            }).then(_ => {
              console.log(_)
              if (_.success) {
                this.$message.success('保存成功')
                this.$router.go(-1)
              } else {
                this.$message.error(_.msg)
              }
            })
          } else {
            createTemplate(this.form).then(_ => {
              if (_.success) {
                this.$message.success('保存成功')
                this.$router.go(-1)
              } else {
                this.$message.error(_.msg)
              }
            })
          }
          return 1
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getProductCodesOptions(event, cb) {
      if (!event) {
        const array = []
        cb(array)
        return
      }
      getProductCodes({
        deviceMainSub: this.form.deviceMainSub,
        deviceTemplateCode: '',
        productCode: event
      }).then(res => {
        cb(res.data.map(item => ({
          value: item.productCode,
          label: item.productCode,
          name: item.productName
        })))
      })
    },
    addProductCode() {
      this.form.productCodeDTOS.push(JSON.parse(JSON.stringify({
        productCode: '',
        productName: '',
        fromDetail: false
      })))
    },
    deleteProductCode(key) {
      this.form.productCodeDTOS.splice(key, 1)
    },
    langOptionChange(event, key, currentItem) {
      console.log(currentItem)
      this.langOptions.forEach(itm => {
        if (itm.value === event) {
          currentItem.languageName = itm.label
        }
      })
      const langArray = JSON.parse(JSON.stringify(this.form.templateNameDTOS))
      const item = langArray.find((itm, index) => itm.language === event && key !== index)
      if (item) {
        this.$message.error(`已有${item.languageName}模板名称`)
      }
    }
  }
}
</script>
<style lang="scss">
.visual-template-config {
  position: relative;
  min-height: calc(100vh - 120px);
  padding: 30px 0 0 120px;
  box-sizing: border-box;
  .creates-footer {
    border-top: 2px solid #ebedf0;
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 76px;
    width: 100%;
    background: #ffffff;
    .c-ruleform-but {
      display: flex;
      justify-content: center;
    }
  }
}
.template-name {
  display: flex;
  align-items: center;
}
</style>